<template>
    <Section
        class="bg-white padding-y-2 comparison-table"
        :width="data.primary.slice_width"
    >
        <div
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.title)" 
        />
        <div
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.teaser)" 
        />

        <div class="table-container margin-top-4">
            <table>
                <thead>
                    <tr>
                        <th />

                        <td v-for="(card, index) in cards" :key="index" class="logo">
                            <nuxt-link :to="$prismic.linkResolver(card)">
                                <PrismicImage
                                    :img="card.data.image"
                                    w="200"
                                />
                            </nuxt-link>
                        </td>
                    </tr>

                    <tr>
                        <th>{{ $translate('credit_card', 'Kreditkort') }}</th>
                        <td v-for="(card, index) in cards" 
                            :key="index" 
                            style="font-weight: bold;"
                        >
                            {{ card.data.title }}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="!data.primary.disable_general_info">
                        <tr>
                            <th>{{ $translate('yearly_fee', 'Årskostnad') }}</th>
                            <td v-for="(card, index) in cards" 
                                :key="index" 
                                :class="{ highlight: checkLowest('yearlyCost', card) }" 
                            >
                                <span v-html="card.model.yearlyCostHtml" />
                                <tooltip 
                                    v-if="card.model.hasFirstYearOffer" 
                                    class="margin-left-1 black" 
                                    :tooltip-text="$translate('card_info_yearly_fee_tooltip', '')" 
                                />
                            </td>
                        </tr>

                        <tr>
                            <th>{{ $translate('card_info_highest_credit', 'Högsta kredit') }}</th>
                            <td v-for="(card, index) in cards" 
                                :key="index" 
                                :class="{ highlight: checkHighest('maxCredit', card) }" 
                                v-html="maxCredit(card)" 
                            />
                        </tr>

                        <tr>
                            <th>{{ $translate('card_info_effective_interest', 'Effektiv ränta') }}</th>
                            <td v-for="(card, index) in cards" 
                                :key="index" 
                                :class="{ highlight: checkLowest('minEffectiveInterestPossible', card) }" 
                                v-html="effectiveInterest(card)" 
                            />
                        </tr>
                    </template>

                    <template v-if="data.primary.table_type === 'mobile_payments'">
                        <PaymentRow 
                            v-for="(payment, index) in mobilePayments"
                            :key="index"
                            :payment="payment"
                            :cards="cards"
                            :checkmark-boolean="checkmarkBoolean" 
                        />
                    </template>

                    <template v-else-if="data.primary.table_type === 'pros_cons'">
                        <Pros :cards="cards" />
                        <Cons :cards="cards" />
                    </template>

                    <template v-else-if="data.primary.table_type === 'insurances'">
                        <InsuranceRow 
                            v-for="(insurance, index) in insurancesToShow" 
                            :key="index"
                            :insurance="insurance"
                            :checkmark-boolean="checkmarkBoolean" 
                        />
                    </template>

                    <template v-else-if="data.primary.table_type === 'fees'">
                        <FeeRow 
                            v-for="(fee, index) in fees" 
                            :key="index"
                            :fee="fee"
                            :cards="cards"
                            :lowest="lowest" 
                            :check-lowest="checkLowest" 
                        />
                    </template>                
    
                    <CustomContentRow 
                        v-for="(rowContent, index) in rowsContent" :key="index" 
                        :row-content="rowContent"
                    />

                    <tr>
                        <th>{{ $translate('interest_example', 'Ränteexempel') }}</th>
                        <td v-for="(card, index) in cards" :key="index">
                            <div 
                                v-if="$validateText(card.model.complianceExample)" 
                            >
                                {{ card.model.complianceExample }}
                            </div>
                        </td>
                    </tr> 

                    <tr>
                        <th>{{ $translate('compare_review', 'Recension') }}</th>
                        <td v-for="(card, index) in cards" :key="index">
                            <div class="flex">
                                <nuxt-link :to="$prismic.linkResolver(card)" class="white btn btn-md btn-blue">
                                    {{ $translate('button_read_review', 'Läs hela recensionen') }}
                                </nuxt-link>
                            </div>
                        </td>
                    </tr> 

                    <tr>
                        <th> {{ $translate('button_apply', 'Till ansökan') }} </th>
                        <td v-for="(card, index) in cards" :key="index">
                            <div class="flex">
                                <ButtonTarget
                                    :link="card"
                                    placement="comparison"
                                    :text="$translate('button_apply', 'Till ansökan')"
                                    background="orange"
                                    size="md"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Section>
</template>

<script>
import InsuranceRow from '@/components/comparison-table/insurances/InsuranceRow.vue';
import FeeRow from '@/components/comparison-table/fees/FeeRow.vue';
import Pros from '@/components/comparison-table/pros-cons/Pros.vue';
import Cons from '@/components/comparison-table/pros-cons/Cons.vue';
import PaymentRow from '@/components/comparison-table/mobile-payments/PaymentRow.vue';
import CustomContentRow from '@/components/comparison-table/custom-content/CustomContentRow.vue';
import Tooltip from '@/components/features/Tooltip.vue';

import FeesMixin from '@/components/comparison-table/fees/Mixins.js';
import InsuranceMixin from '@/components/comparison-table/insurances/Mixins.js';
import PaymentsMixin from '@/components/comparison-table/mobile-payments/Mixins.js';
import CustomContentMixin from '@/components/comparison-table/custom-content/Mixins.js';

export default {
    components: {
        PaymentRow,
        InsuranceRow,
        Pros,
        Cons,
        FeeRow,
        CustomContentRow,
        Tooltip
    },
    mixins: [FeesMixin, InsuranceMixin, PaymentsMixin, CustomContentMixin],
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        cards() {
            return this.data.items
                .map(item => item.card)
                .filter(card => card.id)
                .map(card => {
                    const c = Object.assign({}, this.$store.state.cards[card.id]);
                    c.model = new this.$models.CreditCard(c);
                    return c;
                });
        },
        lowest() {
            const lowest = {
                minEffectiveInterestPossible: undefined,
                yearlyCost: undefined,
                overdraftFee: undefined,
                latePaymentFee: undefined,
                penaltyInterest: undefined,
                reminderFee: undefined,
                aviFeeEInvoice: undefined,
                currencyExchangeFeePercentage: undefined
            };
            const lowCheckers = Object.keys(lowest);
            let cardCategoryValues = [];

            lowCheckers.forEach(category => {
                this.cards.forEach(card => {
                    if (category === 'yearlyCost' && card.model.hasFirstYearOffer) {
                        cardCategoryValues.push(card.model.firstYearCost);
                    }
                    else if (category === 'minEffectiveInterestPossible' && card.model.isDebit) {
                        return;
                    }
                    else {
                        cardCategoryValues.push(card.model[category]);
                    }
                });
                lowest[category] = Math.min(...cardCategoryValues);
                cardCategoryValues = [];
            });
            return lowest;
        },
        highest () {
            const highest = {
                maxCredit: undefined
            };

            let cardCategoryValues = [];
            const highCheckers = Object.keys(highest);

            highCheckers.forEach(category => {
                this.cards.forEach(card => {
                    cardCategoryValues.push(card.model[category]);
                });
                highest[category] = Math.max(...cardCategoryValues);
                cardCategoryValues = [];
            });
            return highest;
        },
    },
    methods: {
        checkmarkBoolean (bool){
            if (bool) {
                return '<i class="fas fa-check-circle green" />';
            } 
            return '<i class="fas fa-times-circle red" />'; 
        },
        checkLowest (key, card) {
            if (key === 'yearlyCost' && card.model.hasFirstYearOffer) {
                return card.model.firstYearCost <= this.lowest[key];
            }
            return card.model[key] <= this.lowest[key];
        },
        checkHighest (key, card) {
            return card.model[key] >= this.highest[key];
        },
        maxCredit(card) {
            if (card.model.isCredit) {
                return card.model.getMaxCreditString(this.$translate('bd_unlimited', 'Obegränsad'));
            } 
            return `<span class='padding-x-1 bg-blue white br-2'>${this.$translate('debit_card', 'Debitkort')}</span>`;
        },
        effectiveInterest(card) {
            if (card.model.isCredit) {
                return card.model.effectiveInterestString;
            } 
            return `<span class='padding-x-1 bg-blue white br-2'>${this.$translate('debit_card', 'Debitkort')}</span>`;
        }
    }
    
};
</script>